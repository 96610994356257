import * as React from 'react';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Layout from '../components/Layout';
import Intro from '../components/styles/intro.styled';
import Home from '../components/styles/Home.styled';
import mobileCheck from '../utils/mobileDetector';

import Video from '../components/Video';
// markup
const IndexPage = (): JSX.Element => {
  const [poster, setPoster] = useState(true);
  const clickHandler = (): void => {
    const inFifteenMinutes = new Date(new Date().getTime() + 1 * 900 * 1000);
    Cookies.set('intro', true, {
      expires: inFifteenMinutes,
    });
    setPoster(false);
  };
  useEffect(() => {
    if (!mobileCheck()) {
      if (Cookies.get('intro')) {
        setPoster(false);
      } else {
        setPoster(true);
      }
    } else {
      setPoster(false);
    }
  });
  return (
    <Layout theme="">
      <Home>
        <Video poster={poster} clickHandler={clickHandler} />
        <article className="bio">
          <Intro>
            <p>
              <strong>Tomasz Zienowicz</strong>
            </p>
            <p>
              adwokat, doktor nauk prawnych, wykładowca akademicki, doradca w
              Gdyńskim Centrum Wspierania Przedsiębiorczości, recenzent
              Przeglądu Ustawodawstwa Gospodarczego
            </p>
          </Intro>
        </article>
        <article>
          <Intro>
            <p>
              Kancelaria zajmuje się świadczeniem pomocy prawnej na terenie
              całego kraju, a w szczególności udzielaniem porad prawnych,
              występowaniem przed sądami i urzędami, sporządzeniem umów i opinii
              prawnych oraz opracowywaniem projektów aktów prawnych.
            </p>
          </Intro>
        </article>
        <article>
          <h1>Publikacje</h1>
          <ul>
            <li>
              1. Egzekucja z akcji spółek akcyjnych dopuszczonych do publicznego
              obrotu - uwagi de lege ferenda - Currenda 2003
            </li>
            <li>2. Opcje walutowe w orzecznictwie - Pieniądze i Więź 2011</li>
            <li>
              3. Krótka sprzedaż – Przegląd Ustawodawstwa Gospodarczego 2016
            </li>
            <li>
              4. Transgraniczna działalność inwestycyjna na obszarze OECD 2017 –
              rozprawa doktorska
            </li>
            <li>
              5. Escrow a powiernictwo w kontekście uzasadnionych oczekiwań w
              procesie stanowienia prawa - PN Disputatio 2018
            </li>
            <li>
              6. Opłacalność nielegalnego hazardu - zarys teoretyczny oraz uwagi
              de lege ferenda - PN Disputatio 2018
            </li>
            <li>
              7. Artificial intelligence i singularity w procesie stosowania
              prawa - Prawo Mediów Elektronicznych 2019
            </li>
            <li>
              8. Adekwatność stawek adwokackich – sprawozdanie z badań -
              Palestra 1/2020
            </li>
            <li>
              9. Problematyka kosztów sądowych pomocy prawnej w świetle
              Konwencji o przeprowadzaniu dowodów za granicą w sprawach
              cywilnych lub handlowych sporządzonej w Hadze 18.03.1970 r. –
              uwagi de lege ferenda - Palestra 1-2/2021
            </li>
            <li>
              10. Problematyka związana z brakiem definicji legalnej pojęcia
              „swap” w polskim systemie prawa – uwagi de lege ferenda - Palestra
              7-8/2021
            </li>
            <li>
              11. Relacja między zasadą kontradyktoryjności a zasadą prawdy
              materialnej w polskiej procedurze cywilnej , Głos Prawa Przegląd
              Prawniczy Allerhanda 2021, t. 4, nr 2 (8)
            </li>
            <li>
              12. Surveillance capitalism - rozważania prawnoteoretyczne,
              Palestra 1-2/2023
            </li>
          </ul>
        </article>
        <article>
          <h1>Konferencje</h1>
          <ul>
            <li>
              1. Zjazd Katedr i Zakładów Praw Gospodarczego Publicznego, Gdańsk
              2015
            </li>
            <li>
              2. Aktualne problemy z zakresu przestępczości gospodarczej, Gdańsk
              2018
            </li>
            <li>
              3. Ogólnopolska Konferencja Naukowa pt. "Prawne aspekty udziału
              państwa w gospodarce", Gdańsk 2018
            </li>
            <li>4. Kongres Teorii i Filozofii Prawa, Katowice 2022.</li>
          </ul>
        </article>
      </Home>
    </Layout>
  );
};

export default IndexPage;
