import styled from 'styled-components';

const intro = styled.div`
  p {
    font-size: 1.3rem;
    font-family: 'Times New Roman', Times, serif;
    font-style: italic;
    color: #425563;
    padding: 0;
    @media (min-width: 768px) {
      padding: 0 2rem;
    }
  }
`;

export default intro;
