import styled from 'styled-components';

const Home = styled.div`
  .bio {
    p {
      text-align: left;
      margin-bottom: 0.3rem;
    }
  }
`;

export default Home;
